import { defineStore } from "pinia";

export const useUserDataStore = defineStore("userData", {
  persist: true,
  state: () => ({
    user: { biername: "" } as User,
    auth: { token: "" } as Auth,
    theme: "light",
  }),
  getters: {
    getBiername: (state) => state.user.biername,
    getUser: (state) => state.user,
    getId: (state) => state.user.id,
    isLoggedIn: (state) => state.user.biername !== "" && state.user.biername,
    getToken: (state) => state.auth.token,
    getIsFoxie: (state) => !(state.user.rights >= 10),
    isAdmin: (state) => state.user.rights >= 1000,
    getTheme: (state) => state.theme,
  },
  actions: {
    setAuth(auth: Auth) {
      this.auth = auth;
    },
    setToken(token: string) {
      this.auth.token = token;
    },
    logout() {
      this.user = {} as User;
      this.auth = {} as Auth;
    },
    setUser(user: User) {
      this.user = user;
    },
    toggleTheme() {
      this.theme = this.theme === "light" ? "dark" : "light";
    },
  },
});

export interface User {
  id: string;
  biername: string;
  adresse: string;
  geburtsdatum: string;
  email: string;
  Vorname: string;
  rights: number;
  telefon: string;
  Nachname: string;
}

export interface Auth {
  token: string;
}
