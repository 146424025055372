import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card_text, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "justify-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_switch, {
                  elevation: "0",
                  label: "Dark Mode",
                  onClick: _ctx.toggleTheme,
                  "prepend-icon": "mdi-theme-light-dark",
                  "value-comparator": () => _ctx.isDarkMode
                }, null, 8, ["onClick", "value-comparator"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "justify-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.passwortZuruecksetzten()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Passwort zurücksetzen")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_dialog, { "max-width": "600" }, {
              activator: _withCtx(({ props: activatorProps }) => [
                _createVNode(_component_v_btn, _mergeProps(activatorProps, {
                  text: "Benachrichtigungen einstellen",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getNotificationPreferences()))
                }), null, 16)
              ]),
              default: _withCtx(({ isActive }) => [
                _createVNode(_component_v_card, { title: "Benachrichtigungen" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_v_switch, {
                              label: "Forum",
                              modelValue: _ctx.preferencesforum,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.preferencesforum) = $event))
                            }, null, 8, ["modelValue"]), [
                              [_vShow, _ctx.permissionSet]
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_v_switch, {
                              label: "Termine",
                              modelValue: _ctx.preferencescalendar,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.preferencescalendar) = $event))
                            }, null, 8, ["modelValue"]), [
                              [_vShow, _ctx.permissionSet]
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_v_switch, {
                              label: "Terminzusagen",
                              modelValue: _ctx.preferencesanmeldungen,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.preferencesanmeldungen) = $event))
                            }, null, 8, ["modelValue"]), [
                              [_vShow, _ctx.permissionSet]
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_v_switch, {
                              label: "Terminabsagen",
                              modelValue: _ctx.preferencesabmeldungen,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.preferencesabmeldungen) = $event))
                            }, null, 8, ["modelValue"]), [
                              [_vShow, _ctx.permissionSet]
                            ])
                          ]),
                          _: 1
                        }),
                        _withDirectives(_createVNode(_component_v_row, { class: "justify-center" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.askPermissions()))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Berechtigungen vergeben")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 512), [
                          [_vShow, !_ctx.permissionSet]
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          text: "Abbrechen",
                          onClick: ($event: any) => (_ctx.getNotificationPreferences(), (isActive.value = false))
                        }, null, 8, ["onClick"]),
                        _createVNode(_component_v_btn, {
                          color: "error",
                          variant: "flat",
                          onClick: ($event: any) => (_ctx.setNotificationPreferences(), (isActive.value = false))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Speichern")
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.pwResetPositive,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pwResetPositive) = $event))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Ein Link zum Passwort zurücksetzen wurde nun an Deine angegebene Mail-Adresse geschickt. ")
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.pwResetNegative,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pwResetNegative) = $event))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Beim Passwort zurücksetzen trat ein Fehler auf. Bitte versuche es erneut oder wende Dich an die Administratoren. ")
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}