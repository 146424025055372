import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_SettingsTab = _resolveComponent("SettingsTab")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_app = _resolveComponent("v-app")!
  const _directive_scroll = _resolveDirective("scroll")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "ma-auto pa-auto" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.settingsTab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settingsTab) = $event)),
        "max-width": "400"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Einstellungen")
                ]),
                _: 1
              }),
              _createVNode(_component_SettingsTab),
              _createVNode(_component_v_card_actions, { class: "justify-end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "error",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.settingsTab = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Schließen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_navigation_drawer, {
            float: "",
            onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.rail = true)),
            ref: "navBarRef",
            rail: _ctx.rail,
            permanent: "",
            onMouseenter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.rail = false)),
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeNav()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                class: "ma-3",
                src: 
            _ctx.theme.current.value.dark
              ? '/img/Zirkel-white.png'
              : '/img/Zirkel.png'
          ,
                nav: "",
                contain: "",
                "max-width": "80"
              }, null, 8, ["src"]),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_list, {
                density: "compact",
                nav: ""
              }, {
                default: _withCtx(() => [
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 0,
                        "prepend-icon": "mdi-home-city",
                        title: "Home",
                        value: "home",
                        to: "/"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 1,
                        "prepend-icon": "mdi-calendar",
                        title: "Calendar",
                        value: "account",
                        to: "/calendar"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 2,
                        "prepend-icon": "mdi-forum",
                        title: "Forum",
                        value: "Forum",
                        to: "/forum"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 3,
                        "prepend-icon": "mdi-contacts",
                        title: "Anschriften",
                        value: "Anschriften",
                        to: "/anschriften"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 4,
                        "prepend-icon": "mdi-firefox",
                        title: "Spefüxe",
                        value: "Spefüxe",
                        to: "/spefuexe"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 5,
                        "prepend-icon": "mdi-folder",
                        title: "Dateien",
                        value: "dateien",
                        to: "/dateien"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 6,
                        "prepend-icon": "mdi-camera-burst",
                        title: "Gallery",
                        value: "Gallery",
                        to: "/Gallery"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 7,
                        "prepend-icon": "mdi-poll",
                        title: "Abstimmungen",
                        value: "Abstimmungen",
                        to: "/Abstimmungen"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list, { nav: "" }, {
                    default: _withCtx(() => [
                      (_ctx.isLoggedIn)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 0,
                            "prepend-icon": "mdi-cog",
                            title: "Einstellungen",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.settingsTab = true))
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.isLoggedIn)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 1,
                            "prepend-icon": "mdi-login",
                            title: "Login",
                            to: "/login"
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.isLoggedIn)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 2,
                            "prepend-icon": "mdi-logout",
                            title: _ctx.logoutString,
                            onClick: _ctx.logout
                          }, null, 8, ["title", "onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["rail"]),
          _createVNode(_component_v_main, { class: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, null, {
            activator: _withCtx(({ props }) => [
              _withDirectives(_createVNode(_component_v_btn, _mergeProps(props, {
                class: "text-end ma-5",
                onClick: _ctx.toTop,
                position: "fixed",
                location: "bottom right",
                icon: "mdi-arrow-up",
                style: {"z-index":"2000"},
                color: "primary",
                elevation: "8"
              }), null, 16, ["onClick"]), [
                [_directive_scroll, _ctx.onScroll],
                [_vShow, _ctx.fab]
              ])
            ]),
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", null, "Zurück nach oben", 512), [
                [_vShow, _ctx.fab]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}