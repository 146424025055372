import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "12",
            sm: "12",
            class: "pa-3 d-flex flex-column"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "flex d-flex flex-column" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Es befindet sich aktuell im Umbau. ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Hier ensteht das neue System für die Prima Oldenburgensis")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_row, { class: "justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "12",
            sm: "12",
            class: "pa-3 d-flex flex-column"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "flex d-flex flex-column" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Forum")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Das alte Forum soll hierdurch abgelöst werden.")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "12",
            sm: "12",
            class: "pa-3 d-flex flex-column"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "flex d-flex flex-column" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Kalender")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Zu den ACAC und sonstigen Veranstaltungen sollen hier Termine im Kalender verankert werden können, zu welchen jeder Primenser sich an- oder abmelden kann.")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "12",
            sm: "12",
            class: "pa-3 d-flex flex-column"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "flex d-flex flex-column" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Spefüxe")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Den Alten Herren und Inaktiven soll hier die Möglichkeit gegeben werden, den Aktiven einfach Spefüxe melden zu können, auch wenn diese vielleicht noch nicht ganz im richtigen Alter sind. Zum Richtigen Zeitpunkt wird eine Errinnerung rausgeschickt.")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "flex d-flex flex-column" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Anschriftenverzeichnis")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Du hast gerade eine Adresse oder Telefonnummer nicht zur Hand oder möchtest, dass deine Conaktiven deine neue Adresse einfach finden können? Trage sie hier einfach in das Anschriftenverzeichnis ein!")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "flex d-flex flex-column" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Weitere Ideen?")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Du wirst die Entwickler dieser Plattform einfach erreichen können. Wir sind offen für Vorschläge zu neuen Funktionen oder zur Verbesserung bereits bestehender!")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}