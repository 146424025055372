import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useUserDataStore } from "@/store/userDataStore";
enum authLevel {
  ADMIN,
  USER,
  MODERATOR,
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: HomeView,
  },
  {
    path: "/forum",
    name: "forumMultiple",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: () =>
      import(/* webpackChunkName: "forum" */ "../views/ForumView.vue"),
  },
  {
    path: "/forum/:forumId/:threadId",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: () =>
      import(/* webpackChunkName: "forum" */ "../views/ForumThreadView.vue"),
  },
  {
    path: "/forum/:forumId",
    name: "singleForum",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: () =>
      import(/* webpackChunkName: "forum" */ "../views/ForumCategoryView.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    }, // route level code-splitting
    // this generates a separate chunk (calendar.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/CalendarView.vue"),
  },
  {
    path: "/anschriften",
    name: "anschriften",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    }, // route level code-splitting
    // this generates a separate chunk (anschriften.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "anschriften" */ "../views/AnschriftenView.vue"
      ),
  },
  {
    path: "/spefuexe",
    name: "spefuexe",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    }, // route level code-splitting
    // this generates a separate chunk (spefuexe.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "spefuexe" */ "../views/SpefuexeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/resetPassword/:uuid?",
    name: "ResetPassword",
    component: () =>
      import(
        /* webChunkName. "ResetPassword" */ "../views/ResetPasswordView.vue"
      ),
  },
  {
    path: "/dateien/:folder(.*)?",
    name: "dateien",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: () =>
      import(/* webChunkName. "dateien" */ "../views/DateienView.vue"),
  },
  {
    path: "/Gallery/:folder?",
    name: "gallery",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: () =>
      import(/* webChunkName. "Galery" */ "../views/GalleryView.vue"),
  },
  {
    path: "/Abstimmungen",
    name: "AbstimmungenÜbersicht",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: () =>
      import(/* webChunkName. "Abstimmung" */ "../views/AbstimmungenView.vue"),
  },
  {
    path: "/Abstimmungen/:abstimmungId",
    name: "SingleAbstimmung",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: () =>
      import(
        /* webChunkName. "Abstimmung" */ "../views/SingleAbstimmungView.vue"
      ),
  },
  {
    path: "/Zeitstrahl",
    name: "Zeitstrahl",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: () =>
      import(/* webChunkName. "Zeitstrahl" */ "../views/ZeitstrahlView.vue"),
  },
  {
    path: "/Familien",
    name: "Familien",
    meta: {
      requiresAuth: true,
      level: authLevel.USER,
    },
    component: () =>
      import(/* webChunkName. "Familien" */ "../views/FamilieView.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash };
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const userDataStore = useUserDataStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!userDataStore.isLoggedIn) {
      next({
        path: "/login",
        query: {
          ref: to.fullPath,
        },
      });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
